"use client";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";
import { createContext, useEffect, useRef } from "react";

export const FFMpegProviderContext = createContext<FFmpeg | null>(null);

export function FFMpegProvider({ children }: { children: React.ReactNode }) {
	const ffmpegRef = useRef<FFmpeg | null>(typeof window !== "undefined" ? new FFmpeg() : null);

	useEffect(() => {
		async function load() {
			const ffmpeg = ffmpegRef.current;
			if (typeof window === "undefined" || !ffmpeg) return;
			const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
			// toBlobURL is used to bypass CORS issue, urls with the same domain can be used directly.
			await ffmpeg.load({
				coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
				wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
			});
		}

		load();
	}, []);

	return <FFMpegProviderContext.Provider value={ffmpegRef.current}>{children}</FFMpegProviderContext.Provider>;
}
