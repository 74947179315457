"use client";

import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import clsx from "clsx";
import { Check, X } from "lucide-react";

export function Toaster() {
	const { toasts } = useToast();

	return (
		<ToastProvider>
			{toasts.map(function ({ id, title, description, action, ...props }) {
				return (
					<Toast key={id} {...props}>
						<div className="grid gap-1">
							<div className="flex flex-row items-center gap-2">
								<div
									className={clsx(
										"flex items-center justify-center rounded-full border p-0.5",
										props.variant === "successful" ? "border-green-500" : "border-red-500",
									)}
								>
									{props.variant === "successful" ? <Check size={14} /> : <X size={14} />}
								</div>
								{title && <ToastTitle>{title}</ToastTitle>}
							</div>
							{description && <ToastDescription>{description}</ToastDescription>}
						</div>
						{action}
						<ToastClose />
					</Toast>
				);
			})}
			<ToastViewport />
		</ToastProvider>
	);
}
